import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import SessionManager from 'src/login/sessionManager';
import { logout } from 'src/utils/login';

interface GuestGuardProps {
    children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
    if (SessionManager.isLoggedIn()) {
        if (window.location.href.includes('login?logoutBefore=true')) {
            logout();
        }
        return <Navigate to="/dashboard/rfp" replace />;
    }

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
