import SessionManager from 'src/login/sessionManager';
import { addSearchParams } from 'src/utils/addSearchParams';

export const appendAccessToken = (url) =>
    SessionManager.getAccessToken() && SessionManager.getRefreshToken()
        ? addSearchParams(url, {
              tcbToken: SessionManager.getAccessToken(),
              tcbRefreshToken: SessionManager.getRefreshToken(),
          })
        : url;
