import { useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

export const organizationWorkerRolesSelector = (state: GlobalState) =>
    state.organizationWorkerRoles;

export const organizationWorkerRolesSelectorData = (state: GlobalState) =>
    organizationWorkerRolesSelector(state).data;

export const useOrganizationWorkerRoles = () => useAppSelector(organizationWorkerRolesSelectorData);
