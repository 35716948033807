class SessionManager {
    private REFRESH_TOKEN_KEY: string = 'jwtRefreshToken';

    private ACCESS_TOKEN_KEY: string = 'jwtAccessToken';

    tenants: Array<number> = [];

    private accessToken: string = null;

    private refreshToken: string = null;

    constructor() {
        this.load();
    }

    /* Getters Setters */
    getAccessToken() {
        if (!this.accessToken) {
            this.load();
        }
        return this.accessToken;
    }

    setAccessToken(token: string) {
        this.accessToken = token;
        this.save();
    }

    getRefreshToken() {
        if (!this.refreshToken) {
            this.load();
        }
        return this.refreshToken;
    }

    setRefreshToken(token: string) {
        this.refreshToken = token;
        this.save();
    }

    /* Load Save */
    load() {
        this.refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        this.accessToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }

    save() {
        if (this.refreshToken === null) {
            localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        } else {
            localStorage.setItem(this.REFRESH_TOKEN_KEY, this.refreshToken);
        }
        if (this.accessToken === null) {
            localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        } else {
            localStorage.setItem(this.ACCESS_TOKEN_KEY, this.accessToken);
        }
    }

    logout() {
        this.accessToken = null;
        this.refreshToken = null;
        this.save();
    }

    isLoggedIn(): boolean {
        return this.accessToken !== null;
    }
}

export default new SessionManager();
