import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { t } from '@lingui/macro';

import SessionManager from 'src/login/sessionManager';
import { unathorizedApi as api } from 'src/services/apiService';
import DismissibleToast from 'src/utils/DismissibleToast';

const gracePeriod = 30; // seconds

export const jwtPayload = (token: string) => {
    if (token) {
        return JSON.parse(atob(token.split('.')[1]));
    }
    return null;
};

export const loginCall = async (email: string, password: string) => {
    const token = await api.post('/api/jwt/login/', { email, password });
    return {
        accessToken: token?.data?.access as string,
        refreshToken: token?.data?.refresh as string,
        otpToken: token?.data?.otp_required_token as string,
    };
};

export const refreshCall = async (refreshToken: string) => {
    const token = await api
        .post('/api/jwt/refresh/', { refresh: refreshToken })
        .catch((error) => error);
    if (token instanceof Error) {
        SessionManager.logout();

        const { response } = token as AxiosError;
        if (response?.status === 401) {
            window.location.assign('/login');
        } else if (window?.location?.search?.includes('?token=')) {
            window.location.replace(window.location.href.split('?')[0]);
        } else {
            window.location.reload();
        }
    }
    return {
        accessToken: token?.data?.access as string,
    };
};

export const refreshAndBlacklistCall = async (refreshToken: string) => {
    const token = await api
        .post('/api/jwt/refresh-and-blacklist/', { refresh: refreshToken })
        .catch((error) => error);
    if (token instanceof Error) {
        SessionManager.logout();

        const { response } = token as AxiosError;
        if (response?.status === 401) {
            window.location.assign('/login');
        } else if (window?.location?.search?.includes('?token=')) {
            window.location.replace(window.location.href.split('?')[0]);
        } else {
            window.location.reload();
        }
    }
    return {
        accessToken: token?.data?.access as string,
        refreshToken: token?.data?.refresh as string,
    };
};

export const logout = async () => {
    // clear localstorage
    await api.get('/api/jwt/logout/');
    SessionManager.logout();
};

export const logoutUserSessionExpired = async () => {
    await logout();
    window.location.replace(`/login?next=${window.location.pathname}&note=session_expired`);
    toast.error(DismissibleToast(t`Your session has expired. Please login again.`), {
        id: 'session-expired',
    });
};

export const getAccessToken = async () => {
    const accessToken = SessionManager.getAccessToken();

    if (
        !!jwtPayload(accessToken)?.apps?.includes('references') &&
        !window.location.href.includes('references')
    ) {
        return null;
    }

    if (accessToken) {
        const expire = jwtPayload(accessToken).exp;
        const now = new Date().getTime() / 1000;

        if (expire - now < gracePeriod) {
            const refreshToken = SessionManager.getRefreshToken();
            if (refreshToken) {
                const token = await refreshCall(refreshToken);
                if (token.accessToken) {
                    SessionManager.setAccessToken(token.accessToken);
                    return token.accessToken;
                }
            }

            return null;
        }
    } else {
        const refreshToken = SessionManager.getRefreshToken();
        if (refreshToken) {
            const token = await refreshCall(refreshToken);
            if (token.accessToken) {
                SessionManager.setAccessToken(token.accessToken);
                return token.accessToken;
            }
        }

        return null;
    }

    return accessToken;
};
