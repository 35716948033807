import graphql from 'babel-plugin-relay/macro';
import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { i18n } from '@lingui/core';

import SessionManager from 'src/login/sessionManager';
import { unathorizedApi } from 'src/services/apiService';
import type { JWTUser } from 'src/types/user';
import { jwtPayload, logout, refreshAndBlacklistCall } from 'src/utils/login';

const JWTContextUserQuery = graphql`
    query JWTContextUserQuery {
        userApps
        userProfile {
            user {
                id
                firstName
                ssoConnected
                ssoConnectUrl
                lastName
                company {
                    id
                    companyName
                    companyNameAlias
                    companySizeEmployees {
                        id
                        published
                        employeesRangeDe
                        employeesRangeEn
                        rangeMin
                        rangeMax
                    }
                    generalInfo {
                        website
                        turnover
                    }
                    mainAddress {
                        address {
                            addressLine1
                            addressLine2
                            addressLine3
                            city
                            country {
                                id
                                commonNameEn
                                commonNameDe
                            }
                            postalCode
                        }
                    }
                }
                mobilePhone
                jobTitle
                salutation {
                    id
                    salutationEn
                    salutationDe
                }
                academicTitle {
                    id
                    titleEn
                    titleDe
                }
            }
            language
            trackingConsent
            formattingLocale
            email
            emailValidated
            profileCompleted
            isStaff
            hasPassword
            isClient
            isAgent
            isConsultant
            isReferencesEmailUser
            briefRole
            apaduaRole
            exploreRole
            apalyticsRole
            monitorRole
            chatwootSession
            chatwootToken
            activeRfps
            activeRfqs
        }
    }
`;

interface AuthContextValue {
    user: JWTUser | null;
    logout: () => Promise<void>;
    fetchUserProfile: () => Promise<void>;
    recoverPassword: (email: string) => Promise<void>;
    setUser: any;
}

const AuthContext = createContext<AuthContextValue>({
    user: null,
    logout: () => Promise.resolve(),
    fetchUserProfile: () => Promise.resolve(),
    recoverPassword: () => Promise.resolve(),
    setUser: null,
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState(null);
    const environment = useRelayEnvironment();

    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const authToken = searchParams.get('token');
    const refrToken = searchParams.get('refreshToken');

    const fetchUserProfile = () =>
        new Promise<void>((resolve, reject) => {
            fetchQuery(
                environment,
                JWTContextUserQuery,
                {},
                { fetchPolicy: 'network-only' },
            ).subscribe({
                error: (error) => {
                    reject();
                },
                next: (data) => {
                    if (data.userProfile) {
                        if (data.userProfile.language) {
                            i18n.activate(data.userProfile.language);
                        } else {
                            window.localStorage.setItem(
                                'referencesLanguage',
                                window.localStorage.getItem('referencesLanguage') || 'en',
                            );
                            i18n.activate(window.localStorage.getItem('referencesLanguage'));
                        }
                        let trackingConsent;
                        if (data.userApps.every((app) => app === 'references')) {
                            trackingConsent = window.localStorage.getItem('referencesGaConsent');
                        } else {
                            trackingConsent = data.userProfile.trackingConsent;
                        }
                        setUser({
                            id: data.userProfile?.user?.id,
                            firstName: data.userProfile?.user?.firstName,
                            lastName: data.userProfile?.user?.lastName,
                            language: data.userProfile.language,
                            trackingConsent,
                            formattingLocale: data.userProfile.formattingLocale,
                            email: data.userProfile.email,
                            emailValidated: data.userProfile.emailValidated,
                            profileCompleted: data.userProfile.profileCompleted,
                            isStaff: data.userProfile.isStaff,
                            hasPassword: data.userProfile.hasPassword,
                            isClient: data.userProfile.isClient,
                            isAgent: data.userProfile.isAgent,
                            isConsultant: data.userProfile.isConsultant,
                            isReferencesEmailUser: data.userProfile.isReferencesEmailUser,
                            briefRole: data.userProfile.briefRole,
                            apaduaRole: data.userProfile.apaduaRole,
                            monitorRole: data.userProfile.monitorRole,
                            exploreRole: data.userProfile.exploreRole,
                            apalyticsRole: data.userProfile.apalyticsRole,
                            chatwootSession: data.userProfile.chatwootSession,
                            chatwootToken: data.userProfile.chatwootToken,
                            companyId: data.userProfile?.user?.company?.id || '',
                            companyName: data.userProfile?.user?.company?.companyName || '',
                            company: data.userProfile?.user?.company,
                            mobilePhone: data.userProfile?.user?.mobilePhone,
                            jobTitle: data.userProfile?.user?.jobTitle,
                            salutation: data.userProfile?.user?.salutation,
                            academicTitle: data.userProfile?.user?.academicTitle,
                            apps: data.userApps,
                            sessionExpired: false,
                            activeRfps: data.userProfile?.activeRfps,
                            activeRfqs: data.userProfile?.activeRfqs,
                            ssoConnected: data.userProfile?.user.ssoConnected,
                            ssoConnectUrl: data.userProfile?.user.ssoConnectUrl,
                        } as JWTUser);
                    } else {
                        setUser(null);
                    }
                    resolve();
                },
            });
        });

    const logoutAndClear = async () => {
        await logout();
        setUser(null);
    };

    const recoverPassword = async (email: string): Promise<void> => {
        await unathorizedApi.post('api/recover-password/', { email });
    };

    useEffect(() => {
        const tokenLogin = async () => {
            const decoded = jwtPayload(authToken);
            const currentToken = SessionManager.getAccessToken();
            let currentDocoded = { user_id: null };
            if (currentToken) {
                currentDocoded = jwtPayload(currentToken);
            }
            if (currentDocoded.user_id !== decoded.user_id) {
                await logout();
                let res = { accessToken: null, refreshToken: null };
                if (decoded.token_type === 'refresh') {
                    res = await refreshAndBlacklistCall(authToken);
                    if (res.accessToken) {
                        SessionManager.setAccessToken(res.accessToken);
                        SessionManager.setRefreshToken(res.refreshToken);
                        await fetchUserProfile();
                        navigate(location.pathname, { replace: true });
                    }
                } else {
                    SessionManager.setAccessToken(authToken);
                    if (refrToken) {
                        SessionManager.setRefreshToken(refrToken);
                    }
                    await fetchUserProfile();
                    navigate(location.pathname, { replace: true });
                }
            } else {
                await fetchUserProfile();
                navigate(location.pathname, { replace: true });
            }
        };

        if (authToken) {
            tokenLogin();
        }
    }, []);

    useEffect(() => {
        if (SessionManager.isLoggedIn()) {
            fetchUserProfile();
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                logout: logoutAndClear,
                fetchUserProfile,
                recoverPassword,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
