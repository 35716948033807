import { ApaduaAPI } from './ApaduaAPI';
import { APIHeaders } from './HttpAPI';

export class ApaduaLegacyAPI extends ApaduaAPI {
    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        return {
            ...super.getHeaders(),
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        };
    }
}
