import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { OrganizationWorkerRolesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const organizationWorkerRolesSlice = createSlice({
    name: 'organizationWorkerRoles',
    initialState,
    reducers: {},
    extraReducers(builder) {
        OrganizationWorkerRolesFetcher.reducers(builder);
    },
});

export default organizationWorkerRolesSlice.reducer;
