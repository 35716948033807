import { t } from '@lingui/macro';

const useRfpStatus = (status: string) => {
    let tooltip = '';
    let label = '';

    switch (status) {
        case 'draft':
            tooltip = t`Draft | The event was created and saved in draft.`;
            label = t`Draft`;
            break;
        case 'sent':
            tooltip = t`Active | The event was submitted to the supplier. Bidding phase active.`;
            label = t`Sent`;
            break;
        case 'completed':
            tooltip = t`Completed | The event was concluded.`;
            label = t`Completed`;
            break;
        case 'onHold':
            tooltip = t`On Hold | The event was set on hold.`;
            label = t`On hold`;
            break;
        case 'cancelled':
            tooltip = t`Cancelled | The event was cancelled.`;
            label = t`Cancelled`;
            break;
        case 'requested':
            tooltip = t`Requested | A colleague requested a new event`;
            label = t`Requested`;
            break;
        case 'rejected':
            tooltip = t`Rejected | The supplier has rejected to participate in this proposal.`;
            label = t`Rejected`;
            break;
        case 'invited':
            tooltip = t`Invited | The supplier received the invitation to this event.`;
            label = t`Invited`;
            break;
        case 'accepted':
            tooltip = t`Accepted | The supplier has confirmed participation in the event after reviewing the content.`;
            label = t`Accepted`;
            break;
        case 'opened':
            tooltip = t`Opened | The supplier has agreed to the terms & conditions and has reviewed the content of this event.`;
            label = t`Opened`;
            break;
        case 'submitted':
            tooltip = t`Submitted | The supplier has submitted a proposal.`;
            label = t`Submitted`;
            break;
        case 'awarded':
            tooltip = t`Awarded | You awarded the proposal of the supplier.`;
            label = t`Awarded`;
            break;
        case 'dismissed':
            tooltip = t`Dismissed | You dismissed the supplier from this event.`;
            label = t`Dismissed`;
            break;
        case 'lost':
            tooltip = t`Lost | You did not award the proposal of the supplier.`;
            label = t`Lost`;
            break;
        case 'declined':
            tooltip = t`Declined | The supplier has declined the terms & conditions to participate.`;
            label = t`Declined`;
            break;
        default:
            tooltip = '';
            label = '';
    }

    return { label, tooltip };
};

export default useRfpStatus;
