import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import toast from 'react-hot-toast';
import { put, takeEvery } from 'redux-saga/effects';

import { t } from '@lingui/macro';

import SessionManager from 'src/login/sessionManager';
import { setLoginLoading } from 'src/login/store/reducer';
import DismissibleToast from 'src/utils/DismissibleToast';
import { loginCall } from 'src/utils/login';

import { loginAction, navigateAction } from './actions';

function* loginSaga(action) {
    yield put({
        type: setLoginLoading.type,
        payload: true,
    });
    const { payload } = action;
    try {
        const response = yield loginCall(payload.email, payload.password);

        if (response?.otpToken) {
            const params = {
                jwtOtpToken: response.otpToken,
                next: payload.next,
            };
            const queryParams = new URLSearchParams(omitBy(params, isEmpty));
            const path = `/login/otp-validation?${queryParams.toString()}`;
            yield put({
                type: navigateAction.type,
                payload: { path },
            });
        } else {
            SessionManager.setAccessToken(response.accessToken);
            SessionManager.setRefreshToken(response.refreshToken);
            yield put({
                type: navigateAction.type,
                payload: { path: payload.next ?? '/dashboard/rfp' },
            });
        }
    } catch (error) {
        toast.error(DismissibleToast(t`Invalid email or password`));
    } finally {
        yield put({
            type: setLoginLoading.type,
            payload: false,
        });
    }
}

export function* watchLoginAction() {
    yield takeEvery(loginAction.type, loginSaga);
}
