import { call, put, takeEvery } from 'redux-saga/effects';

import { t } from '@lingui/macro';

import { LoginAPI } from 'src/apis/loginAPI';
import SessionManager from 'src/login/sessionManager';
import { setLoginError, setLoginLoading, submitOTPSuccess } from 'src/login/store/reducer';

import { navigateAction, submitOTPAction } from './actions';

interface ValidateOTPPayload {
    otp_required_token: string;
    otp_code: string;
}

const serializeValidateOTPPayload = (data): ValidateOTPPayload => ({
    otp_required_token: data.otpToken,
    otp_code: data.otpCode,
});

function* submitOTPSaga(action) {
    try {
        yield put({
            type: setLoginLoading.type,
            payload: true,
        });
        const api = new LoginAPI();
        const { payload } = action;

        // extract otp token from params
        const searchParams = new URLSearchParams(window.location.search);

        const otpToken = searchParams.get('jwtOtpToken');
        const nextURL = searchParams.get('next');

        const requestPayload = serializeValidateOTPPayload({ otpToken, otpCode: payload });
        const loginOTPResponse = yield call(api.validateOTP, requestPayload);

        if (loginOTPResponse) {
            SessionManager.setAccessToken(loginOTPResponse.access);
            SessionManager.setRefreshToken(loginOTPResponse.refresh);

            yield put({
                type: navigateAction.type,
                payload: { path: nextURL ?? '/dashboard', options: { replace: true } },
            });
            yield put({
                type: submitOTPSuccess.type,
            });
        }
    } catch (e) {
        if (e.response.status === 400 || e.response.status === 401) {
            yield put({
                type: setLoginError.type,
                payload: t`Invalid OTP`,
            });
        }
        if (e.response) {
            const { data } = e?.response;
            if (data && data[0] && parseInt(data[0].status, 10) === 401) {
                yield put({
                    type: setLoginError.type,
                    payload: t`Invalid OTP`,
                });
            }
        }
    } finally {
        yield put({
            type: setLoginLoading.type,
            payload: false,
        });
    }
}

export function* watchSubmitOTPAction() {
    yield takeEvery(submitOTPAction.type, submitOTPSaga);
}
