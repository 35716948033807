import { ProposalDocumentLegacyAPI } from 'src/apis/proposalDocumentLegacyAPI';

import Fetcher from '../fetcher';
import { sanitizeDocumentTypes } from './sanitizers';

const api = new ProposalDocumentLegacyAPI();

export const ProposalDocumentTypesFetcher = new Fetcher(
    'proposalDocumentTypes/list',
    async () => api.getDocumentTypes(),
    sanitizeDocumentTypes,
);
