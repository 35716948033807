import { toRelayId } from 'src/relay/utils';
import { appendAccessToken } from 'src/utils/appendAccessToken';

export const notificationOptions = {
    // General notifications
    // X003 - New questions on RFQ/RFP
    NEWQUESTIONS: {
        src: '/static/icons/questions.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/${context.appName}/${context.projectId}/evaluation/questions`,
        },
        requiredContextFields: ['appName', 'projectId', 'projectTitle'],
    },
    // X005 - New answers to questions on RFP/RFQ
    NEWANSWERSTOQUESTIONS: {
        src: '/static/icons/questions.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/${context.appName}/${context.projectId}/proposals/${context.proposalId}/setup/questions`,
        },
        requiredContextFields: [
            'appName',
            'projectId',
            'proposalId',
            'projectTitle',
            'clientCompanyAlias',
        ],
    },
    // X006 - New announcement on RFP/RFQ
    NEWANNOUNCEMENT: {
        src: '/static/icons/announcement.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context, user) =>
                user.isClient
                    ? `/dashboard/${context.appName}/${context.projectId}/evaluation/announcements`
                    : `/dashboard/${context.appName}/${context.projectId}/proposals/${context.proposalId}/setup/announcements`,
        },
        requiredContextFields: [
            'appName',
            'projectId',
            'proposalId',
            'projectTitle',
            'clientCompanyAlias',
        ],
    },
    // X007 - New client call-back was posted
    NEWCLIENTCALLBACKWASPOSTED: {
        src: '/static/icons/callback.svg',
        longFormat: true,
        requiredInputName: null,
        link: null,
        requiredContextFields: [
            'appName',
            'projectId',
            'projectTitle',
            'supplierCompanyAlias',
            'requestMessage',
        ],
    },
    // X009 - New organization account
    NEWORGANIZATIONACCOUNT: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: null,
        link: {
            external: false,
            getUrl: () => '/dashboard/organization/users',
        },
        requiredContextFields: [
            'userCompanyName',
            'userFirstName',
            'userFirstName',
            'userEmailAddress',
            'userPhoneNumber',
        ],
    },
    // X010 - New user account activated
    NEWUSERACCOUNTACTIVATED: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: null,
        link: {
            external: false,
            getUrl: () => '/dashboard/organization/users',
        },
        requiredContextFields: [
            'userCompanyName',
            'userFirstName',
            'userFirstName',
            'userEmailAddress',
            'userPhoneNumber',
        ],
    },
    // X011 - New support question
    NEWSUPPORTQUESTION: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: null,
        link: null,
        requiredContextFields: [
            'userCompanyAlias',
            'userCompanyName',
            'userFirstName',
            'userFirstName',
            'userPhoneNumber',
            'requestTimestamp',
            'requestMessage',
        ],
    },
    // RFP - Source notifications
    // P001 - Proposal Invitation
    PROPOSALINVITATION: {
        src: '/static/icons/new.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: () => '/dashboard/rfp',
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'proposalDueDate',
        ],
    },
    // P002 - Reminder for Invite to RFP
    REMINDERFORINVITETORFP: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: () => '/dashboard/rfp',
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'proposalDueDate',
        ],
    },
    REMINDERFORCONFIRMATIONOFRRFP: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/proposal/${context.proposalId}/summary`,
                ),
        },
        requiredContextFields: ['projectId', 'proposalId'],
    },
    // P004 - User Assigned to RFP
    USERASSIGNEDTORFP: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context, user) =>
                user.isClient
                    ? `/project/${context.projectId}`
                    : `/dashboard/rfp/${toRelayId(
                          'QuoteNode',
                          context.projectId,
                      )}/proposals/${toRelayId('ProposalNode', context.proposalId)}/setup/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
        ],
    },
    // P005 - Invite to concept evaluation
    INVITETOCONCEPTEVALUATION: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/technical_evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P006 - Invite to pitch evaluation
    INVITETOPITCHEVALUATION: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P007 - Evaluation reminder
    EVALUATIONREMINDER: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P008 - Pitch Evaluation reminder
    PITCHEVALUATIONREMINDER: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/pitch_evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P009 - Pitch invitation
    PITCHINVITATION: {
        src: '/static/icons/message.svg',
        longFormat: true,
        requiredInputName: null,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/proposal/${context.proposalId}/beauty_contest`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
            'pitchDate',
            'pitchInvitationText',
        ],
    },
    // P010 - New Project Request
    NEWPROJECTREQUEST: {
        src: '/static/icons/new.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(`${window.APADUA_SOURCE}app/process/${context.projectId}`),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'requesterFirstName',
            'requesterLastName',
        ],
    },
    // P012 - New proposal submitted
    NEWPROPOSALSUBMITTEDRFP: {
        src: '/static/icons/new_proposal.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/proposals`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierUserFirstName',
            'supplierUserLastName',
            'supplierCompanyAlias',
        ],
    },
    // P013 - Proposal awarding to supplier
    PROPOSALAWARDINGTOSUPPLIER: {
        src: '/static/icons/won.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/proposal/${context.proposalId}/result`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
        ],
    },
    // P014 - Proposal awarding client internal
    PROPOSALAWARDINGCLIENTINTERNAL: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: null,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/evaluation/summary`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompanyAlias',
            'awardingUserFirstName',
            'awardingUserLastName',
            'awardingUserEmail',
            'supplierAddressLine1',
            'supplierAddressLine2',
            'supplierAddressLine3',
            'supplierZip',
            'supplierCity',
            'supplierVat',
            'awardingTimestamp',
            'awardedProjectVolume',
            'projectCurrency',
            'plannedStartDate',
        ],
    },
    // P015 - Proposal cancellation by supplier
    PROPOSALCANCELLATIONBYSUPPLIER: {
        src: '/static/icons/lost.svg',
        longFormat: true,
        requiredInputName: 'cancellationReasonText',
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/proposals`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompanyAlias',
            'cancellationReasonText',
        ],
    },
    // P016 - Request new proposal
    REQUESTNEWPROPOSAL: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/proposal/${context.proposalId}/proposal`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // P017 - New project status
    NEWPROJECTSTATUS: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context, user) => {
                if (user.isClient) {
                    return `/dashboard/rfp/${toRelayId(
                        'QuoteNode',
                        context.projectId,
                    )}/evaluation/summary`;
                }
                if (context.newStatus !== 'cancelled') {
                    return `/dashboard/rfp/${toRelayId(
                        'QuoteNode',
                        context.projectId,
                    )}/proposals/${toRelayId('ProposalNode', context.proposalId)}/setup/summary`;
                }
                return 'dashboard/rfp/';
            },
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'newStatus',
        ],
    },
    // P018 - Project Awarding due
    PROJECTAWARDINGDUE: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/evaluation/summary`,
        },
        requiredContextFields: ['projectId', 'projectName', 'clientCompanyAlias'],
    },
    // P019 - Project Awarding due date reminder
    PROJECTAWARDINGDUEDATEREMINDER: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/evaluation/summary`,
        },
        requiredContextFields: ['projectId', 'projectName', 'clientCompanyAlias'],
    },
    // P020 - Supplier dismissed from RFP
    SUPPLIERDISMISSEDFROMRFP: {
        src: '/static/icons/lost.svg',
        longFormat: true,
        requiredInputName: 'clientRejectionReason',
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/proposals/${toRelayId(
                    'ProposalNode',
                    context.proposalId,
                )}/setup/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientRejectionReason',
        ],
    },
    // P021 - Proposal lost message to supplier
    PROPOSALLOSTMESSAGETOSUPPLIER: {
        src: '/static/icons/lost.svg',
        longFormat: true,
        requiredInputName: 'feedbackText',
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/proposals/${toRelayId(
                    'ProposalNode',
                    context.proposalId,
                )}/setup/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'feedbackText',
        ],
    },
    // P022 - New project created
    NEWPROJECTCREATEDRFP: {
        src: '/static/icons/new.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) => `/project/${context.projectId}`,
        },
        requiredContextFields: [
            'projectId',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P023 - New project submitted
    NEWPROJECTSUBMITTEDRFP: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/evaluation/summary`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // P024 - Supplier rejects TnCs
    SUPPLIERREJECTSTNCS: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/proposals`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompany',
        ],
    },
    // P025 - Supplier confirms TnCs
    SUPPLIERCONFIRMSTNCS: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/proposals`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompany',
        ],
    },
    // P026 - Pitch invitation accepted
    PITCHINVITATIONACCEPTED: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/pitch_evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompany',
        ],
    },
    // P027 - Pitch invitation declined
    PITCHINVITATIONDECLINED: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                appendAccessToken(
                    `${window.APADUA_SOURCE}app/project/${context.projectId}/client/pitch_evaluation`,
                ),
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'supplierCompany',
        ],
    },
    // P028 - Timeline change
    TIMELINECHANGE: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context, user) =>
                user.isClient
                    ? `/dashboard/rfp/${toRelayId('QuoteNode', context.quoteId)}/evaluation/summary`
                    : `/dashboard/rfp/${toRelayId(
                          'QuoteNode',
                          context.quoteId,
                      )}/proposals/${toRelayId('ProposalNode', context.projectId)}/setup/summary`,
        },
        requiredContextFields: [
            'quoteId',
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'clientCompanyName',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // P029 - Conditions accepted
    CONDITIONSACCEPTED: {
        src: '/static/icons/info.svg',
        longFormat: true,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/proposals/${toRelayId(
                    'ProposalNode',
                    context.proposalId,
                )}/setup/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'supplierFirstName',
            'supplierLastName',
        ],
    },
    // P031 - Reminder for proposal due date
    REMINDERFORPROPOSALDUEDATE: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/proposals/${toRelayId(
                    'ProposalNode',
                    context.proposalId,
                )}/setup/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'supplierFirstName',
            'supplierLastName',
        ],
    },
    // P032 - Proposal Participation Confirmation
    PROPOSALPARTICIPATIONCONFIRMATION: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: true,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/evaluation/summary`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'supplierFirstName',
            'supplierLastName',
        ],
    },
    // P033 - Successful Proposal Submission
    SUCCESSFULPROPOSALSUBMISSION: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/rfp/${toRelayId('QuoteNode', context.projectId)}/proposals/${toRelayId(
                    'ProposalNode',
                    context.proposalId,
                )}/setup/summary`,
        },
        requiredContextFields: [
            'project_id',
            'proposal_id',
            'client_company_alias',
            'project_id',
            'project_name',
            'supplier_company_alias',
            'supplier_full_name',
            'supplier_email',
            'recipient_full_name',
            'project_link',
        ],
    },
    // RFQ - Rate card notifications
    // Q001 - Invite to RFQ
    INVITETORFQ: {
        src: '/static/icons/new.svg',
        longFormat: true,
        requiredInputName: 'proposalDueDate',
        link: {
            external: false,
            getUrl: () => '/dashboard/ratecards',
        },
        requiredContextFields: ['clientCompanyAlias', 'projectName', 'proposalDueDate'],
    },
    // Q002 - Reminder for Invite to RFQ
    REMINDERFORINVITETORFQ: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: 'proposalDueDate',
        link: {
            external: false,
            getUrl: () => '/dashboard/ratecards',
        },
        requiredContextFields: ['clientCompanyAlias', 'projectName', 'proposalDueDate'],
    },
    // Q003 - Reminder for confirmation of RFQ
    REMINDERFORCONFIRMATIONOFRFQ: {
        src: '/static/icons/info.svg',
        longFormat: true,
        requiredInputName: 'proposalDueDate',
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/tender-introduction`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'clientCompanyAlias',
            'projectName',
            'proposalDueDate',
        ],
    },
    // Q004 - User Assigned to RFQ
    USERASSIGNEDTORFQ: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context, user) =>
                user.isClient
                    ? `/dashboard/ratecards/${context.projectId}/setup/tender-planning`
                    : `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/tender-introduction`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'clientCompanyAlias',
            'projectHeader',
            'assigningUserFirstName',
            'assigningUserLastName',
        ],
    },
    // Q005 - New proposal submitted
    NEWPROPOSALSUBMITTEDRFQ: {
        src: '/static/icons/new_proposal.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/bids`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
            'supplierCompanyAlias',
        ],
    },
    // Q006 - New rates submitted
    NEWRATESSUBMITTED: {
        src: '/static/icons/new_proposal.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/bids`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
            'supplierCompanyAlias',
        ],
    },
    // Q007 - Rates confirmed
    RATESCONFIRMED: {
        src: '/static/icons/won.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/ratecard`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // Q008 - Rates rejected
    RATESREJECTED: {
        src: '/static/icons/lost.svg',
        longFormat: true,
        requiredInputName: 'clientRejectionReason',
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/ratecard`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'clientRejectionReason',
        ],
    },
    // Q009 - Rate revision requested
    RATEREVISIONREQUESTED: {
        src: '/static/icons/message.svg',
        longFormat: true,
        requiredInputName: 'clientRejectionReason',
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/ratecard`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'clientRejectionReason',
        ],
    },
    // Q010 - Proposal revision requested
    PROPOSALREVISIONREQUESTED: {
        src: '/static/icons/message.svg',
        longFormat: true,
        requiredInputName: 'clientRejectionReason',
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/ratecard`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'clientRejectionReason',
        ],
    },
    // Q011 - Proposal accepted
    PROPOSALACCEPTED: {
        src: '/static/icons/won.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/tender-introduction`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // Q012 - Proposal deleted
    PROPOSALDELETED: {
        src: '/static/icons/lost.svg',
        longFormat: false,
        link: null,
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // Q013 - Proposal dismissed
    PROPOSALDISMISSED: {
        src: '/static/icons/lost.svg',
        longFormat: false,
        requiredInputName: 'clientRejectionReason',
        link: null,
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
        ],
    },
    // Q014 - Proposal due date reminder
    PROPOSALDUEDATEREMINDER: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/ratecard`,
        },
        requiredContextFields: ['projectId', 'proposalId', 'projectName', 'clientCompanyAlias'],
    },
    PROPOSALAWARDINGDUE: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/proposals`,
        },
        requiredContextFields: ['projectId'],
    },
    PROPOSALAWARDINGDUEDATEREMINDER: {
        src: '/static/icons/lost.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/proposals`,
        },
        requiredContextFields: ['projectId'],
    },
    // Q017 - New Rate Card Tender created
    NEWPROJECTCREATEDRFQ: {
        src: '/static/icons/new.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/setup/tender-planning`,
        },
        requiredContextFields: [
            'projectId',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // Q018 - New Rate Card Tender submitted
    NEWPROJECTSUBMITTEDRFQ: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/overview`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'projectOwnerFirstName',
            'projectOwnerLastName',
        ],
    },
    // Q019 - New Rate Card Tender submitted
    PROJECTINVITATIONACCEPTEDRFQ: {
        src: '/static/icons/new_proposal.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/proposals`,
        },
        requiredContextFields: [
            'projectId',
            'projectName',
            'clientCompanyAlias',
            'userFirstName',
            'userLastName',
            'supplierCompanyAlias',
        ],
    },
    DEPRECATED: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: null,
        requiredContextFields: [],
    },
    // Q020 - New project status RFQ
    NEWPROJECTSTATUSRFQ: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context, user) =>
                user.isClient
                    ? `/dashboard/ratecards/${context.projectId}/evaluation/proposals`
                    : `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/tender-introduction`,
        },
        requiredContextFields: [
            'projectId',
            'proposalId',
            'projectName',
            'clientCompanyAlias',
            'clientUserFirstName',
            'clientUserLastName',
            'newStatus',
        ],
    },
    // Q021 - Edit mode reminder
    EDITMODEREMINDER: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) => `/dashboard/ratecards/${context.projectId}/evaluation/edit/info`,
        },
        requiredContextFields: ['projectId', 'projectName', 'clientCompanyAlias'],
    },
    // Q022 - Successful Proposal Submission
    SUCCESSFULRATECARDPROPOSALSUBMISSION: {
        src: '/static/icons/info.svg',
        longFormat: false,
        link: {
            external: false,
            getUrl: (context) =>
                `/dashboard/ratecards/${context.projectId}/proposals/${context.proposalId}/setup/tender-introduction`,
        },
        requiredContextFields: [
            'project_id',
            'proposal_id',
            'client_company_alias',
            'project_name',
            'supplier_company_alias',
            'supplier_full_name',
            'supplier_email',
            'recipient_full_name',
            'project_link',
        ],
    },
};
