import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { ProposalDocumentTypesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const proposalDocumentTypesSlice = createSlice({
    name: 'proposalDocumentTypes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ProposalDocumentTypesFetcher.reducers(builder);
    },
});

export default proposalDocumentTypesSlice.reducer;
