import { AxiosError } from 'axios';

import { ApaduaLegacyAPI } from 'src/apis/ApaduaLegacyAPI';

import { QuotesLegacyAPIError } from './quotesLegacyAPIError';

/*
This class has been made in order to support Submit RFP step 6, until all steps are fully migrated
to the new UI plus the APIS.

Being used only in -> ui/src/pages/project/components/projectReviewAndSubmitContainer/submitRFP.tsx
*/

export class QuotesLegacyAPI extends ApaduaLegacyAPI {
    resource: string = 'api/quotes/';

    getQuote(id: number): any {
        return super.get(`${id}`);
    }

    update(payload: any, id): any {
        return this.put(`${id}/`, payload);
    }

    submit(id: any): any {
        return this.post(`${id}/quote-proposals/`, {});
    }

    // eslint-disable-next-line
    handleError(error: AxiosError): any {
        const { errors } = error.response.data;
        let errorMessage = '';
        const errorList = [];
        errors.forEach((err, index) => {
            const { detail, source, code } = err;
            const { pointer } = source;
            const field = pointer.split('/').pop();
            errorMessage += `Error ${index + 1}: ${detail} [${field}] \n`;
            const errorObject = { code, message: errorMessage };
            errorList.push(errorObject);
        });
        const err = new QuotesLegacyAPIError({
            errors: errorList,
            name: 'APIError',
            code: errorList[0]?.code,
        });
        throw err;
    }
}
