import { OrganizationWorkerRolesLegacyAPI } from 'src/apis/organizationWorkerRolesLegacyAPI';

import Fetcher from '../fetcher';
import { sanitizeWorkerRoles } from './sanitizers';

const api = new OrganizationWorkerRolesLegacyAPI();

export const OrganizationWorkerRolesFetcher = new Fetcher(
    'organizationWorkerRoles/list',
    async () => api.getWorkerRoles(),
    sanitizeWorkerRoles,
);
